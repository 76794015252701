import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import Layout from 'components/Layout/Layout'
import Seo from 'components/Layout/Seo'
import {
  BusinessCasesSlice,
  ClaimsSlice,
  ClientsSlice,
  HeroSlice,
  TemplateStartersSlice,
  TestimonialsSlice,
} from 'components/Slices'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query LandingQuery {
      hero: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 65
            width: 800
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
        publicURL
      }
    }
  `)

  const pageName = 'Semplates - The AWS SES Template Editor'
  const subtitle =
    "Unlock the full potential of Amazon SES with Semplates' email template editor. Design and publish personalized, responsive and branded emails with a few clicks."
  const embedVideo = 'https://www.youtube.com/embed/EzLPtkqbD3I?autoplay=1'
  const video = 'https://www.youtube.com/watch?v=EzLPtkqbD3I&t=5s'
  const image = getImage(data.hero)
  const script = [
    {
      '@type': 'VideoObject',
      name: pageName,
      description: subtitle,
      thumbnailUrl: [`https://semplates.io${data.hero.publicURL}`],
      embedUrl: embedVideo,
      contentUrl: video,
      uploadDate: '2023-08-04T08:00:00+00:00',
    },
  ]

  const breadcrumbList = [{ position: 1, name: pageName, path: 'landing' }]

  return (
    <Layout>
      <Seo
        title={pageName}
        pathname="landing"
        breadcrumbList={breadcrumbList}
        organizationLogo
        script={script}
      />
      <HeroSlice image={image} />
      <ClientsSlice />
      <ClaimsSlice />
      <BusinessCasesSlice />
      <TestimonialsSlice />
      <TemplateStartersSlice />
    </Layout>
  )
}

export default IndexPage
